<template>
  <div class="goods-content">
    <div class="search-bar">
      <input type="text" placeholder="请输入要搜索的数据" v-model="terms" @input="search"><span @click="back">确定</span>
    </div>
      <div class="goods-list">
        <div class="good-item" v-for="(item,index) in goodsList" :key="index" @click="selectGood(index)">
          {{item.name}}
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      terms: '',
      goodsList: [],
      category_name: '',
      category_id: null,
      goodInfo: {},
      checked: false
    }
  },
  methods: {
    getGoods (terms) {
      this.$api.goods.getGoods({ terms: terms }).then(res => {
        console.log(res)
        this.goodsList = res.data.result
      })
    },
    search () {
      this.getGoods(this.terms)
    },
    selectGood (index) {
      this.$api.goods.cate(this.goodsList[index].catId).then(res => {
        this.category_name = res.data.category_name
        this.category_id = res.data.shop_category_id
      }).then(() => {
        this.$api.goods.goodDetail(this.goodsList[index].id).then(res => {
          this.goodInfo = res.data.result
        }).then(() => {
          console.log(this.goodInfo)
          this.$router.push({
            name: 'GoodSell',
            params: {
              info: this.$route.query.info,
              mode: 0,
              good_name: this.goodInfo.name,
              category_name: this.category_name,
              category_id: this.category_id,
              imgList: this.goodInfo.images
            }
          })
        })
      })
    },
    back () {
      this.$router.push({
        name: 'GoodSell',
        params: {
          info: this.$route.query.info,
          mode: 0,
          good_name: this.terms
        }
      })
    }
  },
  created () {
    console.log(this.$route.query.info)
    this.getGoods(this.terms)
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
